const arrData = {

    hire: {
        telegram: {
            dataId: 'hire',
            key: 'l1',
            link: 'https://t.me/AleksandrPoteryaev95',
            imageWebp: '/img/telegram-icon.webp',
            imagePng: '/img/telegram-icon.png',
            imageSvg: '/img/telegram-icon.svg',
            altText: 'telegram-icon',
        },
        whatsapp: {
            dataId: 'hire',
            key: 'l2',
            link: 'https://wa.me/79154782352',
            imageWebp: '/img/whatsapp-icon.webp',
            imagePng: '/img/whatsapp-icon.png',
            imageSvg: '/img/whatsapp-icon.svg',
            altText: 'whatsapp-icon',
        },
        gmail: {
            dataId: 'hire',
            key: 'l3',
            link: 'mailto:sp202037@gmail.com?subject=Работа',
            imageWebp: '/img/gmail-icon.webp',
            imagePng: '/img/gmail-icon.png',
            imageSvg: '/img/gmail-icon.svg',
            altText: 'gmail-icon',
        },
        yandex: {
            dataId: 'hire',
            key: 'l4',
            link: 'mailto:sp202037@yandex.ru?subject=Работа',
            imageWebp: '/img/yandex-icon.webp',
            imagePng: '/img/yandex-icon.png',
            imageSvg: '/img/yandex-icon.svg',
            altText: 'yandex-icon',
        },
        headHunter: {
            dataId: 'hire',
            key: 'l5',
            link: 'https://hh.ru/resume/80aaf5bfff0309daaf0039ed1f4e35314e7357',
            imageWebp: '/img/headhunter-icon.webp',
            imagePng: '/img/headhunter-icon.png',
            imageSvg: '/img/headhunter-icon.svg',
            altText: 'headhunter-icon',
        },
        linkedIn: {
            dataId: 'hire',
            key: 'l6',
            link: 'https://linkedin.com/in/александр-потеряев-a41069243',
            imageWebp: '/img/linkedin-icon.webp',
            imagePng: '/img/linkedin-icon.png',
            imageSvg: '/img/linkedin-icon.svg',
            altText: 'linkedin-icon',
        }
    },

    resume: {
        headHunter: {
            dataId: 'resume',
            key: 'l7',
            link: 'https://hh.ru/resume/80aaf5bfff0309daaf0039ed1f4e35314e7357',
            imageWebp: '/img/headhunter-icon.webp',
            imagePng: '/img/headhunter-icon.png',
            altText: 'headhunter-icon',
        },
        linkedIn: {
            dataId: 'resume',
            key: 'l8',
            link: 'https://linkedin.com/in/александр-потеряев-a41069243',
            imageWebp: '/img/linkedin-icon.webp',
            imagePng: '/img/linkedin-icon.png',
            altText: 'linkedin-icon',
        }
    }

}

export default arrData;
