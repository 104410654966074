// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Montserrat-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Montserrat-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Montserrat-ExtraLightItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Montserrat-ExtraLightItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/Montserrat-ExtraLight.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/Montserrat-ExtraLight.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/Montserrat-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./fonts/Montserrat-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./fonts/Montserrat-Italic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./fonts/Montserrat-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./fonts/Montserrat-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./fonts/Montserrat-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*================== Add fonts mixin ====================*/
/*=======================================================*/
/*================ Paste fonts in mixin =================*/
/*--------------------- Main fonts ----------------------*/
@font-face {
  font-family: "Montserrat-Bold";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Light";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Italic";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("woff2");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat-Regular";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format("woff2");
  font-weight: 400;
  font-style: normal;
}
/*-------------------------------------------------------*/`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA,0DAAA;AAaA,0DAAA;AAGA,0DAAA;AAEA,0DAAA;AAfI;EACI,8BAgBM;EAfN,kBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;AACR;AAPI;EACI,0CAiBM;EAhBN,kBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;AAQR;AAdI;EACI,oCAkBM;EAjBN,kBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;AAeR;AArBI;EACI,+BAmBM;EAlBN,kBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;AAsBR;AA5BI;EACI,gCAoBM;EAnBN,kBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;AA6BR;AAnCI;EACI,iCAqBM;EApBN,kBAAA;EACA,sHAAA;EAEA,gBAAA;EACA,kBAAA;AAoCR;AAlBA,0DAAA","sourcesContent":["/*================== Add fonts mixin ====================*/\r\n\r\n@mixin font($font_name, $file_name, $weight, $style) {\r\n    @font-face {\r\n        font-family: $font_name;\r\n        font-display: swap;\r\n        src: url(\"/fonts/#{$file_name}.woff\") format(\"woff\"),\r\n        url(\"/fonts/#{$file_name}.woff2\") format(\"woff2\");\r\n        font-weight: #{$weight};\r\n        font-style: #{$style};\r\n    }\r\n}\r\n\r\n/*=======================================================*/\r\n\r\n\r\n/*================ Paste fonts in mixin =================*/\r\n\r\n/*--------------------- Main fonts ----------------------*/\r\n\r\n@include font(\"Montserrat-Bold\", \"Montserrat-Bold\", \"700\", \"normal\");\r\n@include font(\"Montserrat-ExtraLightItalic\", \"Montserrat-ExtraLightItalic\", \"200\", \"italic\");\r\n@include font(\"Montserrat-ExtraLight\", \"Montserrat-ExtraLight\", \"200\", \"normal\");\r\n@include font(\"Montserrat-Light\", \"Montserrat-Light\", \"300\", \"normal\");\r\n@include font(\"Montserrat-Italic\", \"Montserrat-Italic\", \"100\", \"italic\");\r\n@include font(\"Montserrat-Regular\", \"Montserrat-Regular\", \"400\", \"normal\");\r\n\r\n/*-------------------------------------------------------*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
